:root {
    --primaryColor: #ef870f;
}

html {
    height: 100%;
}

.certificatesTabStrip > .k-tabstrip-items-wrapper > .k-tabstrip-items > .k-item {
    width: 50% !important;
    justify-content: center;
}

.certificatesTabStrip > .k-content {
    padding-left: 0px !important;
}

.certificatesTabStrip {
    margin-top: 15px
}


.k-panelbar .k-link {
    color: white !important;
    background-color: var(--primaryColor) !important;
}

.k-panelbar .k-link.k-state-selected.k-state-hover {
    background-color: var(--primaryColor) !important;
}

.k-panelbar .k-link.k-state-selected {
    background-color: var(--primaryColor) !important;
}

.k-panelbar .k-link.k-state-hover {
    background-color: var(--primaryColor) !important;
}

.no-scrollbar .k-grid-header {
    padding: 0 !important;
}

.no-scrollbar .k-grid-content {
    overflow-y: visible;
}

.k-panelbar > .k-item > .k-link .k-icon, .k-panelbar > .k-item > .k-link .k-panelbar-item-icon, .k-panelbar > .k-panelbar-header > .k-link .k-icon, .k-panelbar > .k-panelbar-header > .k-link .k-panelbar-item-icon {
    color: white !important;
}

.levels-table .k-grid-header {
    display: none
}

.right-text-cell {
    text-align: right !important;
}

.k-grid-command {
    background: none !important;
}

.k-content > .k-animation-container {
    width: 100% !important;
}

.k-animation-container {
    z-index: 9999888 !important;
}

.k-tabstrip-items .k-link {
    flex: none !important;
}

.k-tabstrip-items-wrapper .k-item.k-state-active::after {
    border-color: var(--primaryColor) !important;
}

body {
    margin: 0;
    height: 100%;
}

.k-tabstrip-content.k-state-active, .k-tabstrip > .k-content.k-state-active {
    padding-right: 0;
}